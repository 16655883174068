import React, { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import { colors } from "../../theme/colors";
import { Box, Grid } from "@mui/material";
import { generateDynamicComponent, useUtil } from "../../hooks/useUtil";
import { Formik } from "formik";
import { styled } from "@mui/material/styles";
import { MgpText } from "./MgpText";
import { MgpCheckbox } from "./MgpCheckbox";
import { Stack, border, borderBottom, borderTop, minHeight } from "@mui/system";
import { LegendToggle } from "@mui/icons-material";

const Accord = styled(Accordion)(({ theme }) => ({
  width: "100%",
  backgroundColor: "white", // Set background color to white
  borderRadius: "8px", // Set desired border radius
  boxShadow: "none", // Remove shadow for a cleaner look
  marginBottom: "10px", // Space between accordions

  "& .MuiAccordionSummary-root": {
    height: "33px !important",
    minHeight: "auto", // Set minHeight to auto
    fontSize: "13px",
    fontWeight: "500", // Make the text bold for better emphasis
    borderRadius: "8px 8px 0 0", // Round the top corners
  },

  "& .MuiAccordionSummary-root.Mui-expanded": {
    borderRadius: "8px 8px 0 0",
    minHeight: "33px !important", // Maintain roundness on top when expanded
  },

  ".css-xpthdg-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "33px !important",
    // Set the desired height for the Accordion header
  },

  "& .MuiAccordionDetails-root": {
    backgroundColor: colors.CRFBG, // Ensure the details area also has a background color
    borderRadius: "0 0 8px 8px", // Round only the bottom corners
    border: `1px solid ${colors.LIGHT_GRAY}`, // Add borders on all sides
    borderTop: `none`, // Remove the top border to blend with summary
    padding: "10px", // Add padding for content
    // Set a maximum height
    overflow: "hidden", // Hide overflow if content exceeds maxHeight
    transition: "max-height 0.3s ease", // Smooth transition for height changes
  },

  transition: "background-color 0.3s ease",
}));

export const RedAsterisk = styled("span")(({ theme }) => ({
  color: "red", // Change this to the desired color
}));
export default function MgpAccordion({
  splits,
  name,
  title,
  content,
  options,
  values,
  touched,
  errors,
  error,
  handleBlur,
  handleChange,
  setFieldValue,
  obj,
  viewMode,
  required,
  disabled,
  checkboxRequired = true,
  setFieldTouched,
  removeDependence,
  checkfieldvalue,
  setValues,
  commentComponent,
  commentData,
  encountedDataLatestStatus,
  newVisit,
  setCommentData,
  edit,
  parentSection,
  enableEnter,
  reopen,
  setserotype,
  setnotemessage,
  ischeckedaccordion,
  mainTitle,
  followuplable,
  setErrors,
  setTouched,
  submitErrors,
  submitTouched,
  attribute,
  name_change,
}) {
  const textBoxRefs = useRef({});

  // Initialize refs dynamically based on fieldNames
  useEffect(() => {
    if (enableEnter) {
      options.forEach((field, index) => {
        if (!textBoxRefs.current[field.name] && index % 2 === 0) {
          textBoxRefs.current[field.name] = React.createRef();
        }
      });
    }
  }, [options, enableEnter]);

  const [expanded, setExpanded] = useState(
    values?.[name] === undefined ||
      values?.[name] === "" ||
      values?.[name] === false
      ? !checkboxRequired
        ? true
        : false
      : true
  );
  const [checked, setChecked] = useState(
    values?.[name] === undefined ||
      values?.[name] === "" ||
      values?.[name] === false
      ? false
      : true
  );

  useEffect(() => {
    if (
      removeDependence &&
      values?.[name] === "" &&
      values?.[name] !== undefined
    ) {
      if (checkfieldvalue.includes(values?.[name])) {
        const updatedValues = {};
        updatedValues[name] = false;
        removeDependence.forEach((nameToRemove) => {
          updatedValues[nameToRemove] = "";
        });
        // Update the state outside the rendering phase
        setValues((prevValues) => ({
          ...prevValues,
          ...updatedValues,
        }));
      }
    }
    if (!checkboxRequired) {
      if (
        !values?.[name] ||
        values?.[name] === false ||
        values?.[name] === undefined
      ) {
        setFieldValue(name, true);
        setExpanded(true);
      }
    }
  }, [values?.[name]]);
  const handleCheckboxChange = (event) => {
    let updatedValues = { ...values };
    updatedValues[name] = event.target.checked;
    handleChange(name, attribute, updatedValues);
    setFieldValue(name, event.target.checked);
    if (event.target.checked) {
      setExpanded(true);
      if (removeDependence && event.target.checked) {
        if (checkfieldvalue.includes(event.target.checked.toString())) {
          updatedValues[name] = event.target.checked;
          removeDependence.forEach((nameToRemove) => {
            updatedValues[nameToRemove] = "";
          });
          handleChange(name, attribute, updatedValues);
          setValues((prevValues) => ({
            ...prevValues,
            ...updatedValues,
          }));
        }
      }
      required = true;
    } else {
      required = false;
      setExpanded(false);
      if (removeDependence && !event.target.checked) {
        if (checkfieldvalue.includes(event.target.checked.toString())) {
          updatedValues[name] = event.target.checked;
          removeDependence.forEach((nameToRemove) => {
            updatedValues[nameToRemove] = "";
          });
          handleChange(name, attribute, updatedValues);
          setValues((prevValues) => ({
            ...prevValues,
            ...updatedValues,
          }));
        }
      }
    }

    event.stopPropagation(); // Stop the event propagation

    setChecked(event.target.checked);
    // Handle any additional logic here
  };
  const urlWindow = window.location;
  const hasAsterisk = title?.endsWith("*");
  const hasAsteriskmainTitlebasline = mainTitle?.endsWith("*");
  const hasAsteriskmainTitle = followuplable?.endsWith("*");

  return (
    <div>
      {mainTitle ? (
        <div
          style={{
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "13px",
            fontWeight: "500",
            color: colors.CFRBLUE,
            paddingBottom: "4px",
          }}
        >
          {urlWindow.pathname.split("/")[3] === "newVisit" ||
          values?.["basicVisit_typeofvisit"] === "Follow Up Visit" ? (
            followuplable === undefined ? (
              hasAsteriskmainTitlebasline ? (
                <>
                  {mainTitle?.slice(0, -1)}
                  <RedAsterisk>*</RedAsterisk>
                </>
              ) : (
                mainTitle
              )
            ) : hasAsteriskmainTitle ? (
              <>
                {followuplable?.slice(0, -1)}
                <RedAsterisk>*</RedAsterisk>
              </>
            ) : (
              followuplable
            )
          ) : hasAsteriskmainTitlebasline ? (
            <>
              {mainTitle?.slice(0, -1)}
              <RedAsterisk>*</RedAsterisk>
            </>
          ) : (
            mainTitle
          )}
        </div>
      ) : null}

      <Box display={"flex"} alignItems={"start"}>
        <Accord
          name={name}
          id={name}
          expanded={
            values?.[name] === undefined ||
            values?.[name] === "" ||
            values?.[name] === false
              ? false
              : true
          }
          sx={{ boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={options.length ? <ExpandMoreIcon /> : null}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: values?.[name] ? colors.PRIMARY : colors.WHITE,
              color: values?.[name] ? colors.WHITE : colors.NAVBAR,
              fontWeight: 500,
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              {checkboxRequired ? (
                <MgpCheckbox
                  checked={
                    values?.[name] === undefined ||
                    values?.[name] === "" ||
                    values?.[name] === false
                      ? false
                      : true
                  }
                  onChange={handleCheckboxChange}
                  style={{
                    color:
                      values?.[name] === true
                        ? colors.WHITE
                        : encountedDataLatestStatus === 4 ||
                          encountedDataLatestStatus === 6
                        ? colors.SECONDARY_TEXT
                        : disabled || viewMode
                        ? colors.ACCORDION_DISABLED_Gray
                        : colors.SECONDARY_TEXT,
                  }}
                  sx={{
                    marginY: expanded ? "0px" : "0px",
                    height: "8px",
                  }}
                  disabled={
                    encountedDataLatestStatus === 4 ||
                    encountedDataLatestStatus === 6
                      ? !edit
                        ? true
                        : disabled
                      : disabled || viewMode
                      ? true
                      : false
                  } // You can add other props as needed
                />
              ) : null}
              <MgpText
                variant="body2"
                sx={{
                  fontSize: "13px",
                  width: "100%",
                  flexShrink: 0,
                  color: error ? colors.ERROR : null,
                }}
              >
                {hasAsterisk ? (
                  <>
                    {title?.slice(0, -1)}
                    <RedAsterisk>*</RedAsterisk>
                  </>
                ) : name_change != undefined ? (
                  values?.["basicVisit_typeofvisit"] === "Follow Up Visit" ? (
                    name_change[1]
                  ) : (
                    name_change[0]
                  )
                ) : (
                  title
                )}
              </MgpText>
            </Stack>
          </AccordionSummary>
          {options.length ? (
            <AccordionDetails
              onBlur={handleBlur}
              sx={{ background: colors.CRFBG }}
            >
              <Grid container spacing={3} direction="row" alignItems="flex-end">
                {options?.map((attr, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {generateDynamicComponent(
                        { ...attr, parentType: "Accordion", parentName: name },
                        touched,
                        errors,
                        values,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                        obj,
                        viewMode,
                        newVisit,
                        commentComponent,
                        setFieldTouched,
                        commentData,
                        setCommentData,
                        setValues,
                        encountedDataLatestStatus,
                        edit,
                        null,
                        null,
                        parentSection,
                        textBoxRefs,
                        reopen,
                        setserotype,
                        setnotemessage,
                        null,
                        setErrors,
                        setTouched,
                        submitErrors,
                        submitTouched
                      )}
                    </React.Fragment>
                  );
                  // }
                })}
              </Grid>
            </AccordionDetails>
          ) : null}
        </Accord>
      </Box>
    </div>
  );
}
