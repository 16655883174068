import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepButton, StepLabel } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { colors } from "../../theme/colors";
import { postProcessData, preProcessData } from "../../hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { StorageHelper } from "../../helpers";
import { useRef, useCallback } from "react";

export default function MgpProgressBar({
  commentData,
  steps,
  completed,
  activeStep,
  setActiveStep,
  setCompleted,
  setSelectedSection,
  crfErrors,
  crfTouch,
  encountedData,
  formikRef,
  patient_selected_section,
  encountedDataLatestStatus,
  submitErrors,
  params,
  Allerrors,
  setAllerrors,
  forms,
  encounterId,
}) {
  const handleStep = (step, label) => () => {
    if (params?.nationalId || params?.patientId) {
    } else {
      setActiveStep(step);
      setSelectedSection(label);
    }
  };

  function areAllFieldsEmpty(
    data,
    excludeFields = [
      "",
      "typeofmgs",
      "cause",
      "countrylist",
      "created",
      "date",
      "death",
      "doctor",
      "firstVisit",
      "hospital",
      "is",
      "typeofvisits",
      "lastVisit",
      "nationality",
      "others",
      "patient",
      "registryno",
      "status",
      "totalVisits",
      "updated",
      "MGseverity",
      "surgerythymectomys",
      "ImmunosuppressiveTreatment",
      "rapidimmunotherapies",
      "ImmunosuppressiveTreatmentCurrentMedication",
    ]
  ) {
    if (typeof data === "string") {
      if (data.trim() === "") return true;

      try {
        const parsed = JSON.parse(data);
        if (
          parsed.hasOwnProperty("cascadervalue") &&
          parsed.hasOwnProperty("value") &&
          Array.isArray(parsed.cascadervalue) &&
          parsed.cascadervalue.length === 0 &&
          Object.keys(parsed.value).length === 0
        ) {
          return true;
        }
      } catch (e) {
        return false;
      }
      return false;
    }

    if (Array.isArray(data)) {
      return data.every((item) => areAllFieldsEmpty(item, excludeFields));
    }

    if (typeof data === "object" && data !== null) {
      return Object.entries(data).every(([key, value]) => {
        if (excludeFields.includes(key)) return true;

        if (key === "isChecked") {
          return (
            value === "" ||
            value === false ||
            value === null ||
            value === undefined
          );
        }

        return areAllFieldsEmpty(value, excludeFields);
      });
    }

    return data === "" || data === null || data === false || data === undefined;
  }

  const uniqueKeys = completed
    ? [...new Set(Object.keys(completed).map((elem) => elem.split("_")[0]))]
    : [];
  const urlWindow = window.location;
  function getDataFromStorage(key) {
    let storedData = StorageHelper.get(key);
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (e) {
        console.error("Error parsing stored data", e);
        return null;
      }
    }
    return null;
  }

  const sectionsWithEmptyFields = Allerrors;
  return (
    <Box sx={{ width: "100%", paddingLeft: "10px" }}>
      <Stepper
        //  sx={{
        //   "& .MuiStepIcon-root.Mui-active": {
        //     // Handle color based on errors, mandatory data, and filled status
        // color: hasErrors
        //   ? "red"  // If there are errors, color the icon red
        //   : AreAllFieldsEmpty
        //   ? "#808080"  // If all fields are empty, set the color to gray
        //   : !isFilled
        //   ? "#FFA500"  // If fields are not filled, set the color to orange
        //   : colors.PRIMARY,  // If everything is filled and there's no error, set to primary color
        //   },
        // }}
        nonLinear
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => {
          const hasComment =
            commentData?.counts &&
            Object.keys(commentData?.counts)?.some(
              (elem) => elem.split("_")[0] === label.key
            );
          const hasErrors =
            submitErrors &&
            Object.keys(submitErrors)?.some(
              (elem) => elem.split("_")[0] === label.key
            );
          let mandatorydata = postProcessData(formikRef)[label.key];

          let AreAllFieldsEmpty = areAllFieldsEmpty(mandatorydata);
          if (
            encountedDataLatestStatus !== 0 ||
            encountedDataLatestStatus === null
          ) {
            AreAllFieldsEmpty = false;
          }
          if (encountedDataLatestStatus === null) {
            AreAllFieldsEmpty = true;
          }
          if (label.key !== "MedicationRequest") {
            return (
              <Step
                key={label.key}
                completed={completed[index + 1]}
                sx={{
                  // Default inactive state
                  "& .MuiStepIcon-root": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                    // Default inactive color
                    transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1)", // Smooth transition for color
                  },
                  // Active step color
                  "& .MuiStepIcon-root.Mui-active": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                  },
                  // Completed step color
                  "& .MuiStepIcon-root.Mui-completed": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                  },
                  // Disabled step color
                  "& .MuiStepIcon-root.Mui-disabled": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY,
                  },
                  // Optionally, if you need to target a specific step (using the dynamically generated class):
                  "& .css-676sip-MuiStep-root .MuiStepIcon-root": {
                    color:
                      encountedDataLatestStatus !== 0 &&
                      encountedDataLatestStatus !== 4
                        ? colors.PRIMARY
                        : areAllFieldsEmpty(mandatorydata)
                        ? sectionsWithEmptyFields.length === 0
                          ? colors.PRIMARY
                          : sectionsWithEmptyFields.includes(label.key) ||
                            label.key === "ClinicalExamination"
                          ? "red"
                          : (label.key === "ParaClinicalInvestigation" &&
                              (formikRef?.Diagnosis_typeofmg === undefined ||
                                formikRef?.Diagnosis_typeofmg === "")) ||
                            (label.key === "PregnancyHistory" &&
                              (formikRef?.demography_gender === undefined ||
                                formikRef?.demography_gender === ""))
                          ? "red"
                          : colors.PRIMARY
                        : sectionsWithEmptyFields.length === 0
                        ? colors.PRIMARY
                        : sectionsWithEmptyFields.includes(label.key)
                        ? "#FFA500"
                        : colors.PRIMARY, // Just an example to target a specific step's icon
                  },
                }}
              >
                <StepButton
                  color="inherit"
                  onClick={handleStep(index, label.key)}
                >
                  <StepLabel
                    StepIconProps={{
                      icon:
                        index === 0 ? (
                          urlWindow.pathname.split("/")[3] === "Edit" ? (
                            <AssignmentIndIcon />
                          ) : (encountedData?.["basicVisit_typeofvisit"] ===
                              "" &&
                              urlWindow.pathname.split("/")[3] ===
                                "newVisit") ||
                            encountedData?.["basicVisit_typeofvisit"] ===
                              "Follow Up Visit" ? (
                            uniqueKeys.includes(label.key) ? (
                              index + 1
                            ) : !AreAllFieldsEmpty ? (
                              <CheckCircleIcon
                                style={{
                                  color:
                                    encountedDataLatestStatus !== 0 &&
                                    encountedDataLatestStatus !== 4
                                      ? colors.PRIMARY
                                      : areAllFieldsEmpty(mandatorydata)
                                      ? sectionsWithEmptyFields.length === 0
                                        ? colors.PRIMARY
                                        : sectionsWithEmptyFields.includes(
                                            label.key
                                          ) ||
                                          label.key === "ClinicalExamination"
                                        ? "red"
                                        : (label.key ===
                                            "ParaClinicalInvestigation" &&
                                            (formikRef?.Diagnosis_typeofmg ===
                                              undefined ||
                                              formikRef?.Diagnosis_typeofmg ===
                                                "")) ||
                                          (label.key === "PregnancyHistory" &&
                                            (formikRef?.demography_gender ===
                                              undefined ||
                                              formikRef?.demography_gender ===
                                                ""))
                                        ? "red"
                                        : colors.PRIMARY
                                      : sectionsWithEmptyFields.length === 0
                                      ? colors.PRIMARY
                                      : sectionsWithEmptyFields.includes(
                                          label.key
                                        )
                                      ? "#FFA500"
                                      : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                                  fontSize: "20px",
                                }}
                              />
                            ) : (
                              index + 1
                            )
                          ) : (
                            <AssignmentIndIcon />
                          )
                        ) : encountedData?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit" ? (
                          uniqueKeys.includes(label.key) ? (
                            index + 1
                          ) : !AreAllFieldsEmpty ? (
                            <CheckCircleIcon
                              style={{
                                color:
                                  encountedDataLatestStatus !== 0 &&
                                  encountedDataLatestStatus !== 4
                                    ? colors.PRIMARY
                                    : areAllFieldsEmpty(mandatorydata)
                                    ? sectionsWithEmptyFields.length === 0
                                      ? colors.PRIMARY
                                      : sectionsWithEmptyFields.includes(
                                          label.key
                                        ) || label.key === "ClinicalExamination"
                                      ? "red"
                                      : (label.key ===
                                          "ParaClinicalInvestigation" &&
                                          (formikRef?.Diagnosis_typeofmg ===
                                            undefined ||
                                            formikRef?.Diagnosis_typeofmg ===
                                              "")) ||
                                        (label.key === "PregnancyHistory" &&
                                          (formikRef?.demography_gender ===
                                            undefined ||
                                            formikRef?.demography_gender ===
                                              ""))
                                      ? "red"
                                      : colors.PRIMARY
                                    : sectionsWithEmptyFields.length === 0
                                    ? colors.PRIMARY
                                    : sectionsWithEmptyFields.includes(
                                        label.key
                                      )
                                    ? "#FFA500"
                                    : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                                fontSize: "20px",
                              }}
                            />
                          ) : (
                            index + 1
                          )
                        ) : uniqueKeys.includes(label.key) ? (
                          index
                        ) : !AreAllFieldsEmpty ? (
                          <CheckCircleIcon
                            style={{
                              color:
                                encountedDataLatestStatus !== 0 &&
                                encountedDataLatestStatus !== 4
                                  ? colors.PRIMARY
                                  : areAllFieldsEmpty(mandatorydata)
                                  ? sectionsWithEmptyFields.length === 0
                                    ? colors.PRIMARY
                                    : sectionsWithEmptyFields.includes(
                                        label.key
                                      ) || label.key === "ClinicalExamination"
                                    ? "red"
                                    : (label.key ===
                                        "ParaClinicalInvestigation" &&
                                        (formikRef?.Diagnosis_typeofmg ===
                                          undefined ||
                                          formikRef?.Diagnosis_typeofmg ===
                                            "")) ||
                                      (label.key === "PregnancyHistory" &&
                                        (formikRef?.demography_gender ===
                                          undefined ||
                                          formikRef?.demography_gender === ""))
                                    ? "red"
                                    : colors.PRIMARY
                                  : sectionsWithEmptyFields.length === 0
                                  ? colors.PRIMARY
                                  : sectionsWithEmptyFields.includes(label.key)
                                  ? "#FFA500"
                                  : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          index
                        ),
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          // color: hasErrors
                          //   ? "red"
                          //   : areAllFieldsEmpty(mandatorydata)
                          //   ? "red"
                          //   : !isFilled
                          //   ? "#FFA500"
                          //   : colors.PRIMARY,
                          fontSize: "13px",
                          fontFamily: "'Poppins', sans-serif",
                        }}
                      >
                        {label.label}
                      </span>
                      {hasComment ? (
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#808080",
                            marginLeft: "5px",
                            fontSize: "13px",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          }
          return null;
        })}
      </Stepper>
    </Box>
  );
}
